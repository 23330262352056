import { useEffect, useState } from "react";
import animation from "./animation.mp4";
import { disableBodyScroll } from "body-scroll-lock";
import { isMobile } from "react-device-detect";
import toast, { Toaster } from "react-hot-toast";
import ReactGA from "react-ga4";

if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
}

function App() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const rootElement = document.getElementById("root");
    if (rootElement) {
      disableBodyScroll(rootElement);
    }
  }, []);

  const onSignUp = async () => {
    setLoading(true);
    if (email.length === 0 || !email.includes("@")) {
      toast.error("Invalid email", { style: { zIndex: 9999 } });
      setLoading(false);
      return;
    } else {
      try {
        await fetch(`${process.env.REACT_APP_API_URL}/waitlist`, {
          method: "POST",
          body: JSON.stringify({ email }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        toast("Subscribed! You will be notified when the open beta begins", {
          icon: "🚀",
        });
      } catch (e) {
        console.log(e);
        toast.error("error, try again");
      }
    }
    setLoading(false);
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          success: {
            duration: 3000,
            /*theme: {
              primary: "green",
              secondary: "black",
            },*/
          },
        }}
      />
      <video
        src={animation}
        autoPlay
        muted
        playsInline
        loop
        style={{
          width: isMobile ? "150vw" : "100vw",
          left: isMobile ? "-25%" : 0,
          height: isMobile ? "85vh" : "100vh",
          position: "absolute",
          objectFit: isMobile ? "contain" : "cover",
          zIndex: 0,
        }}
      />
      <div style={{ position: "fixed", bottom: 0, right: 0, width: "100vw" }}>
        <div
          style={{
            backgroundColor: "#18181b",
            color: "white",
            width: "calc(100% - 48px)",
            borderTopRightRadius: "16px",
            borderTopLeftRadius: "16px",
            padding: "24px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>Sign up for open beta</div>
            <a
              href="https://discord.gg/UJYTW9kdDZ"
              target="_blank"
              style={{ color: "#7289da" }}
              rel="noreferrer"
            >
              Join Discord
            </a>
          </div>
          <div style={{ display: "flex", marginTop: "16px" }}>
            <input
              type="email"
              placeholder="Email"
              style={{
                width: "calc(100% - 80px)",
                padding: "8px",
                borderRadius: "8px",
                border: "none",
                backgroundColor: "#2c2c2e",
                color: "white",
                fontFamily: "rs",
                outline: "none",
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              onClick={() => !loading && onSignUp()}
              style={{
                opacity: loading ? 0.6 : 1,
                width: "80px",
                padding: "8px",
                borderRadius: "8px",
                border: "none",
                backgroundColor: "#00ff00",
                color: "black",
                marginLeft: "8px",
                cursor: "pointer",
                fontFamily: "rs",
              }}
            >
              {loading ? "..." : "Sign up"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
